import * as Yup from 'yup';

import { TFormFieldsFactory } from '../models/form-field.model';

export const passwordChangeForm: TFormFieldsFactory<'PasswordChangeForm'> = ({ t }) => [
    {
        name: 'currentPassword',
        type: 'password',
        label: t.currentPassword.label,
        initialValue: '',
        schema: Yup.string().required(t.currentPassword.error.required),
    },
    {
        name: 'newPassword',
        type: 'password',
        label: t.newPassword.label,
        initialValue: '',
        schema: Yup.string()
            .min(8, t.newPassword.error.min)
            .max(32, t.newPassword.error.max)
            .required(t.newPassword.error.required),
    },
    {
        name: 'repeatPassword',
        type: 'password',
        label: t.repeatPassword.label,
        initialValue: '',
        schema: Yup.string()
            .min(8, t.repeatPassword.error.min)
            .max(32, t.repeatPassword.error.max)
            .oneOf([Yup.ref('newPassword'), null], t.repeatPassword.error.mustMatch)
            .required(t.repeatPassword.error.required),
    },
    {
        name: 'submit',
        type: 'submit',
        label: t.submit.label,
    },
];
